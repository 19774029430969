<template>
  <div>
    
    <!-- Section Path File/Modul -->
    <v-breadcrumbs 
        :items="pathModul"
        divider="-"
        normal
        class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Content Report - Water Quality Data -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6 pt-0' : 'px-1'" >

        <!-- Row Filtering Input/Select/Button -->
        <v-row align="center">

          <!-- Column Select State -->
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
              <v-select
              :items="listState"
              v-model="modelSelectState"
              label="State"
              prepend-inner-icon="mdi-earth-box"
              hide-details
              solo
              dense
              @change="selectState(modelSelectState)"
              ></v-select>
          </v-col>

          <!-- Column Select Stations -->
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
              <v-select
              :items="listStation"
              v-model="modelSelectStation"
              label="Station"
              prepend-inner-icon="mdi-store-marker"
              hide-details
              solo
              dense
              ></v-select>
          </v-col>

          <!-- Column Select Calendar -->
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
              <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              v-model="modelPopupCalendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  v-model="modelSelectDate"
                  label="Date"
                  prepend-inner-icon="mdi-calendar-today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  solo
                  dense
                  ></v-text-field>
                </template>
                <v-date-picker
                type="month"
                v-model="modelSelectDate"
                @input="modelPopupCalendar = false"
                ></v-date-picker>
              </v-menu>
          </v-col>

          <!-- Generate Table -->
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
              <v-btn
              color="primary"
              class="white--text mt-1"
              solo
              dense
              normal
              @click="getListParams()"
              :loading="loadingBtnGenerateReport"
              :disabled="modelSelectState == '' || modelSelectStation == ''"
              >
                GENERATE REPORT
              </v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  :disabled="tbodyWQD.length == 0"
                  color="excel"
                  class="mt-1 "
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportExcel()"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                      mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
          </v-col>

        </v-row>

        <v-row>

          <v-col cols="12" class="text-md-center">

            <v-data-table
            :headers="theadWQD"
            :items="tbodyWQD"
            :header-props="{ sortIcon: null }"
            class="elevation-1 mt-10"
            :class="{'datatable-mobile' : !$vuetify.breakpoint.lgAndUp}"
            :items-per-page="itemPerPage"
            :mobile-breakpoint="0"
            :loading="loadingDatatable"
            loading-text="Data Loading... Please wait"
            ref="tableWQD"
            >
              <!-- <template v-slot:header>
                  <thead>
                    <tr>
                      <th
                      v-for="(item,i) in theadWQD" 
                      :key="i"
                      class="text-center first sortable"
                      :style="item.group != 'main'? item.colspan == true? '' : 'display:none;' : ''"
                      :rowspan="item.rowspan? '2' : ''"
                      :colspan="item.group != 'main'? item.colspan == true? item.totalColspan : '' : ''"
                      >
                        <span v-if="item.group == 'main'">
                          {{item.text}}
                        </span>
                        <span v-else>
                          {{item.group}}
                        </span>
                        <i aria-hidden="true" class="v-icon notranslate v-data-table-header__icon material-icons theme--light" style="font-size: 18px;"></i>
                      </th>
                    </tr>
                    <tr>
                      <th
                      v-for="(item,i) in theadWQD" 
                      :key="i"
                      style="text-align:center;"
                      :style="item.rowspan? 'display:none;' : ''"
                      >
                        <span>
                          {{item.text}}
                        </span>
                      </th>
                    </tr>
                  </thead>
              </template> -->

            </v-data-table>

          </v-col>

        </v-row>

        <v-row>
          <v-col>
            <p style="font-size:0.90em;text-align:left;">
                <b>
                    Notes: 
                    <v-icon color="insitu" class="ml-2">mdi-circle</v-icon> <span class="mr-5">In-situ</span>
                    <v-icon color="lab">mdi-circle</v-icon> <span>Laboratory</span>
                </b>
            </p>
          </v-col>
        </v-row>

    </v-container>

  </div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx/xlsx.mjs';

export default {

  data: () => ({
    
    // Breadcrumbs Section
    pathModul: [
        {
            text: 'Home',
            disabled: false,
            href: '/Mapviewer'
        },
        {
            text: 'Report - Manual Water Quality Data',
            disabled: true,
            href: '/Report/WaterQualityData',
        },
    ],

    listParams: [],

    // Select/Filter Section
    listState: [],
    modelSelectState: "",
    listAllStation: [],
    listStation: [],
    modelSelectStation: "",
    modelPopupCalendar: false,
    modelSelectDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
    loadingBtnGenerateReport: false,

    // Datatable
    loadingDatatable: false,
    // theadWQD: [
    //   { text: 'State', value: 'state', rowspan: true, sortable: true, divider: false, align: 'center', class: "first", group: "main", colspan: false, totalColspan: 0 },
    //   { text: 'Station ID', value: 'state_id', rowspan: true, sortable: true, divider: false, align: 'center', class: "first", group: "main", colspan: false, totalColspan: 0 },
    //   { text: 'Location', value: 'location', rowspan: true, sortable: true, divider: false, align: 'center', class: "first", group: "main", colspan: false, totalColspan: 0 },
    //   { text: 'Record Date & Time', value: 'record_dt', rowspan: true, sortable: true, divider: false, align: 'center', class: "first", group: "main", colspan: false, totalColspan: 0 },
    //   { text: 'pH', value: 'ph', rowspan: false, sortable: true, divider: false, align: 'center', class: "colspan-true", group: "In-situ", colspan: true, totalColspan: 2 },
    //   { text: 'Salinity (ppt)', value: 'salinity', rowspan: false, sortable: true, divider: false, align: 'center', class: "", group: "In-situ", colspan: false, totalColspan: 2 },
    // ],
    theadWQD: [
      { text: 'State', value: 'STATE_NAME', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
      { text: 'Station ID', value: 'station_a', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
      { text: 'Location', value: 'LOCATION', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
      { text: 'Record Date & Time', value: 'Datetime', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
    ],
    tbodyWQD: [],
    itemPerPage: 10,

  }),

  mounted() {

    // this.getListParams();
    this.getListStateAndStations();
    
  },

  methods: {

    // General

    getListParams(){

      this.listParams = []

      let urlParams = ""
      if(this.modelSelectStation != ""){
        if(this.modelSelectStation == "All Stations"){
          urlParams = "mqm2/miqims/params_manual"
        }
        else{
          urlParams = "mqm2/miqims/params_manual?stationid="+this.modelSelectStation
        }
      }
      else{
        urlParams = "mqm2/miqims/params_manual"
      }

      console.log(this.globalUrl+urlParams);

      axios.get(this.globalUrl+urlParams, {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

          let data = response.data;
          console.log("paramsss",data);

          this.listParams = data;

          this.GenerateTable()
          
      })
      .catch(error => {
          console.log(error);
      })

    },

    getListStateAndStations(){

      this.listState = [];
      this.listStation = []

      axios.get(this.globalUrl+'mqm2/miqims/stations_manual', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

          let data = response.data;

          if(data.length != 0){

            // Add State List
            for(let i in data){
              this.listState.push(data[i].STATE_NAME)
            }

            // Add All Station List
            this.listAllStation = data;

          }
          
          
      })
      .catch(error => {
          console.log(error);
      })

    },

    selectState(state){

      this.listStation = [];

      for(let i in this.listAllStation){
        if(this.listAllStation[i].STATE_NAME == state){
          this.listStation.push(this.listAllStation[i].STATION_ID)
        }
      }

      this.listStation.unshift("All Stations");

    },

    // Datatable
    GenerateTable(){

      // for(let i in this.theadWQD){
      //   if(this.theadWQD[i].group == "In-situ"){
      //     this.theadWQD[i].class = "in-situ"
      //   }
      //   else if(this.theadWQD[i].group == "Laboratory"){
      //     this.theadWQD[i].class = "laboratory"
      //   }
      // }

      this.tbodyWQD = []

      // this.theadWQD = [
      //   { text: 'State', value: 'STATE_NAME', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
      //   { text: 'Station ID', value: 'station_a', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
      //   { text: 'Location', value: 'LOCATION', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
      //   { text: 'Record Date & Time', value: 'Datetime', rowspan: true, sortable: true, divider: false, align: 'center', class: "main", group: "main" },
      // ]

      this.loadingDatatable = true;

      let [year,month] = this.modelSelectDate.split("-")

      let stationID = "";
      if(this.modelSelectStation == "All Stations"){
        stationID = "ALL"
      }
      else{
        stationID = this.modelSelectStation;
      }

      console.log(this.globalUrl+'mqm2/miqims/report_manual?year='+year+'&month='+month+'&stationid='+stationID+'&statename='+this.modelSelectState);

      axios.get(this.globalUrl+'mqm2/miqims/report_manual?year='+year+'&month='+month+'&stationid='+stationID+'&statename='+this.modelSelectState, {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

          let data = response.data;

          console.log(data);

          if(data.length != 0){

            for(let i in data){
              data[i]["Datetime"] = this.useConvertDisplayDatetime(data[i]["DATETIME_INSITU"])
            }

            // Create header params
            let listShortParamsLab = [];
            let listShortParamsSitu = [];
            let keysData = Object.keys(data[0])
            for(let i in data){
              for(let x in keysData){
                if(keysData[x].includes("_INSITU")){
                  listShortParamsSitu.push(keysData[x].split("_INSITU")[0])
                }
                else if(keysData[x].includes("_LAB")){
                  listShortParamsLab.push(keysData[x].split("_LAB")[0])
                }
              }
            }

            
            for(let i in this.listParams){
              for(let x in listShortParamsSitu){
                if(this.listParams[i].reading == listShortParamsSitu[x]){
                  this.theadWQD.push({
                    text: this.listParams[i].fullname, 
                    value: this.listParams[i].reading+"_INSITU",
                    sortable: true, 
                    divider: false, 
                    align: 'center', 
                    class: "in-situ", 
                    group: "In-Situ"
                  })
                }
              }
            }
            
            for(let i in this.listParams){
              for(let x in listShortParamsLab){
                if(this.listParams[i].reading == listShortParamsLab[x]){
                  this.theadWQD.push({
                    text: this.listParams[i].fullname, 
                    value: this.listParams[i].reading+"_LAB",
                    sortable: true, 
                    divider: false, 
                    align: 'center', 
                    class: "laboratory", 
                    group: "Laboratory"
                  })
                }
              }
            }

            this.theadWQD = this.theadWQD.filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)

            this.tbodyWQD = data;

            this.loadingDatatable = false;

          }
          else{

            this.loadingDatatable = false;

          }

          
      })
      .catch(error => {
          console.log(error);
          this.loadingDatatable = false;
      })
      
    },

    // Export Excel
    exportExcel(){

      // this.itemPerPage = -1;

      // const table = document.getElementById("your-table-id");

      // console.log(this.$refs.tableWQD);
      // const table = this.$refs.tableWQD.$el;
      // const ws = XLSX.utils.table_to_sheet(table);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // XLSX.writeFile(wb, "table.xlsx");


    let headerName = "<tr = 'border:none !important' ><td colspan ='"+this.theadWQD.length+"' style = 'text-align: center; border:none !important; font-weight: bold'>MANUAL WATER QUALITY DATA</td></tr>"

        // this.tbodyWQD = [...this.theadWQD,...this.tbodyWQD]

        var style = "<style> td { border: 10px solid;}</style>";
        // var style = "<style> td { background: red;}</style>";
        // var style = "<style> th { border: 10px solid; background-color: #D43BC6;}</style>";

        var uri = "data:application/vnd.ms-excel;base64,",
          template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
            style +
            "</head><body><table>" +
            headerName +
            "{table}</table></body></html>",
          base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
          },
          format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
              return c[p];
            });
          };

        setTimeout(() => {
          let table = document.querySelectorAll(".v-data-table__wrapper ");

          console.log(table);

          // console.log(table[0].innerHTML)

          let tablei = table[0].innerHTML;
          // if (tablei.includes("℃")){
          // tablei = tablei.replaceAll("°C", "&#176;C").replaceAll("µ", "&#181;");

          console.log(tablei);


          let ctx = { worksheet: "Manual Water Quality Data" || "Worksheet", table: tablei };

          let a = document.createElement("a");
          a.href = uri + base64(format(template, ctx));
          a.download = "Manual Water Quality Data.xls";
          //triggering the function
          a.click();

          console.log(a.href);

          this.itemPerPage = 10;

        }, 1000)

    },
    
  },

};
</script>

<style lang="scss">
@import '~scss/main';

/* Section Path File/Modul */
.pathModul{
  padding-bottom: 0px !important;
}

.pathModul > li:nth-child(3) > a{
  color: black !important;
}

.main{
  background-image: linear-gradient(to bottom, #1476bf, #0aafdd) !important;
    color: white !important;
    font-weight: bold !important;
    border: 0.1px solid white !important;
}

.in-situ{
  background-image: linear-gradient(to bottom, #12B1BB, #3CD3DD) !important;
  color: white !important;
  font-weight: bold !important;
  border: 0.1px solid white !important;
}

// .asd:nth-child()

.laboratory{
  background-image: linear-gradient(to bottom, #BB16BC, #D43BC6) !important;
  color: white !important;
  font-weight: bold !important;
  border: 0.1px solid white !important;
}


</style>


